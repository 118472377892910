<template>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-12'>
            <div class='p-0 h-full flex flex-column'>

                <div class='grid'>

                    <div class='col-12 lg:col-7'>
                        <h5 class='mb-0 mt-3'><i class='pi pi-check mr-2'></i> Personel Özlük Bilgileri Listesi</h5>
                    </div>

                    <div class='col-12 lg:col-5 text-right'>

                        <a href='#/Employee/Add'>
                            <Button label='Personel Ekle' icon='pi pi-plus' class='p-button-danger mr-2'></Button>
                        </a>

                        <a href='#/ProjectEmployee/Add'>
                            <Button label='Projeye Personel Ekle' icon='pi pi-plus' class='p-button-help mr-2'></Button>
                        </a>

                        <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success' @click='exportExcel'></Button>
                    </div>

                </div>

                <DataTable :value='dataList' :paginator='true' class='p-datatable-gridlines' :rows='6' dataKey='id' :rowHover='true' v-model:filters='filters' filterDisplay='menu' :loading='loadingIndicator' :filters='filters' responsiveLayout='scroll' :globalFilterFields="[ 'name','surName','identityNumber','cityName','townName','gsm','email','activeProject.name' ]">

                    <template #header>
                        <div class='flex justify-content-between flex-column sm:flex-row'>
                            <span class='p-input-icon-left mb-2'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama' style='width: 100%' />
                            </span>
                        </div>
                    </template>

                    <template #empty>
                        Veri bulunamadı.
                    </template>

                    <template #loading>
                        Personel listesi yükleniyor. Lütfen bekleyin.
                    </template>

                    <Column header='Profil' filterField='name' :showFilterMatchModes='false' bodyClass="text-center" style='width:8rem'>
                        <template #body='{data}'>
                            <img :alt='data.employeePhoto' :src="'http://image.a1catering.com.tr/' + data.employeePhoto" class='border-circle border-0 border-solid cursor-pointer' width='80' height='80' style='vertical-align: middle' @click='edit(data)'/>
                        </template>
                    </Column>

                    <Column field='data.name' header='Ad' filterField='name' :showFilterMatchModes='false'>
                        <template #body='{data}'>
                            <a @click='edit(data)' class="cursor-pointer">
                                {{ data.name }}
                            </a>
                        </template>
                    </Column>

                    <Column field='data.surName' header='Soyad' filterField='surName' :showFilterMatchModes='false'>
                        <template #body='{data}'>
                            <a @click='edit(data)' class="cursor-pointer">
                                {{ data.surName }}
                            </a>
                        </template>
                    </Column>

                    <Column field='identityNumber' header='T.C. Kimlik' filterField='identityNumber' :showFilterMatchModes='false' style='width:8rem'>
                    </Column>

                    <Column field='cityName' header='Şehir' filterField='cityName' :showFilterMatchModes='false' style='width:8rem'>
                    </Column>

                    <Column field='townName' header='İlçe' filterField='townName' :showFilterMatchModes='false' style='width:8rem'>
                    </Column>

                    <Column field='gsm' header='GSM' filterField='gsm' :showFilterMatchModes='false' style='width:8rem'>
                        <template #body='{data}'>
                            <a :href="'tel:+90' + data.gsm" v-if='data.gsm != null && data.gsm != ""'>{{ data.gsm }}</a>
                        </template>
                    </Column>

                    <Column field='gsm' header='WhatsApp' bodyClass='text-center' filterField='gsm' :showFilterMatchModes='false' style='width:6rem'>
                        <template #body='{data}'>
                            <a :href="'https://wa.me/+90' + data.gsm" v-if='data.gsm != null && data.gsm != ""' target="_blank">
                                <Button icon='pi pi-whatsapp' v-if='data.gsm != null && data.gsm != ""' class='p-button-rounded p-button-success'/>
                            </a>           
                        </template>
                    </Column>

                    <Column field='email' header='E-Posta' filterField='email' :showFilterMatchModes='false' style='width:14rem'>
                        <template #body='{data}'>
                            <a :href="'mailto:' + data.email" v-if='data.email != null && data.email != ""'>{{ data.email }}</a>
                        </template>
                    </Column>

                    <Column header='Oluşturan ve Tarihi' filterField='insertDate' dataType='date' style='width:12rem'>
                        <template #body='{data}'>
                            {{ data.managerUser?.name + " " + data.managerUser?.surName }}<br>
                            {{ formatDate(data.insertDate) }}
                        </template>
                    </Column>

                    <Column field='activeProject' header='Proje' filterField='activeProject' :showFilterMatchModes='false' style='width:15rem'>
                        <template #body='{data}'>
                           <span style='color:green' v-if='data.activeProject != null'> 
                                {{ data.activeProject.name  }}
                           </span>
                           <span style='color:red' v-if='data.activeProject == null'> 
                                {{ 'Projeye Eklenmemiş' }} 
                           </span>
                        </template>
                    </Column>

                    <Column field='verified' header='Durum' dataType='boolean' bodyClass='text-center' style='width:6rem' class="hidden">
                        <template #body='{data}'>
                            <i class='pi' style='font-size: 2rem;' :class="{'text-green-500 pi-check-circle': data.isActive, 'text-pink-500 pi-times-circle': !data.isActive}"></i>
                        </template>
                    </Column>

                    <Column field='verified' header='Yasak Durumu' dataType='boolean' bodyClass='text-center' style='width:6rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size: 2rem;' :class="{'text-green-500 pi-check-circle': data.isBanned, 'text-pink-500 pi-times-circle': !data.isBanned}"></i>
                        </template>
                    </Column>

                    <Column header='Düzenle' bodyClass='text-center' style='width:6rem' :exportable='false'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-primary' @click='edit(slotProps.data)' />
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import EmployeeService from '../../services/employeeService';

export default {
    data() {
        return {
            filters: null,
            loadingIndicator: true,
        };
    },
    _firmService: null,
    created() {
        this._employeeService = new EmployeeService();
        this.initFilters();
        this.loadingIndicator = true;
    },
    async mounted() {
        const response = await this._employeeService.getAllEmployees();
        this.dataList = response.data;
        this.loadingIndicator = false;
    },
    methods: {
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },
        edit(item) {
            this.$router.push({ name: 'editEmployee', params: { employeeId: item.id } });
        },
        async exportExcel() {
            this.loadingIndicator = true;
            let excelExports = await this._employeeService.getAllEmployeesExcel();
            let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Çalışanlar ' + moment(this.paymentDate).format('DD.MM.YYYY') + '.xlsx';
            link.click();
            this.loadingIndicator = false;
        },
    },
};
</script>

<style scoped lang='scss'>
//@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}
</style>
